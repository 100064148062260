import getLiveConfig from "@helpers/getLiveConfig"
import Landing from "../components/layout/Landing"
import { REVALIDATE_SECONDS } from "../constants/nextEnv"
import { getTranslation } from "../helpers/i18n"

export default function Custom404({ liveConfig, i18n }) {
  return (
    <Landing i18n={i18n} liveConfig={liveConfig}>
      <h1>{`404 - ${i18n['PAGENMOTFOUND_ERROR']}`}</h1>
    </Landing>
  )
}


export async function getStaticProps() {
  const liveConfig = await getLiveConfig()
  return {
    props: {
      ...getTranslation().props,
      liveConfig,
    },
    revalidate: REVALIDATE_SECONDS,
  }
}